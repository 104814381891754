Handlebars.registerHelper('phoneCode', function (a, b) {
  if (!a || !b) {
    return '';
  }
  var valueConverted;

  if (b.startsWith('0')) {
    valueConverted = b.replace('0', '+');
  } else if (b.startsWith('+')) {
    valueConverted = b;
  } else {
    valueConverted = '+' + b;
  }

  return a + ' (' + valueConverted + ')';
});

$(document).ready(function () {
  var $countryDrp;
  var $stationDrp;
  var currentCountrySelected;

  function handleItemsOfStationBasedOnCountry (countryCode, showVisibleItems) {
    var $itemNoResult = $stationDrp.find('.js-drp-item-no-result');
    var $options = $stationDrp.find('ul > li > a').not($itemNoResult);

    var $listItem = $options.filter(function (index, item) {
      return $(item).data('country-code') === countryCode;
    });

    if (showVisibleItems) {
      $listItem = $listItem.filter(function (index, item) {
        return $(item).is(':visible');
      });
    } else {
      $options.data('hidden', true);
      $listItem.data('hidden', false);
    }

    $itemNoResult.parent().hide();
    $options.parent().hide();
    $listItem.parent().show();
  }

  function handleDropDownCountry () {
    $countryDrp = $('.js-country').drpAutoComplete();
    $countryDrp.on('selectedItem.drpAutoComplete', function (e, item) {
      currentCountrySelected = $(item).data('country-code');
      if (!currentCountrySelected) {
        return;
      }

      handleItemsOfStationBasedOnCountry(currentCountrySelected);

      var $inputText = $stationDrp.find('input[type="text"]');
      $inputText.attr('required', true);
      $inputText.val('').attr('disabled', false);
      $stationDrp.find('div[data-toggle="dropdown"]').removeClass('prevent-event');
      $stationDrp.find('.js-require-marker').show();
    });

    $countryDrp.on('inputChange.drpAutoComplete', function (e, valueChanged) {
      var $inputText = $stationDrp.find('input[type="text"]');

      $inputText.val('').change().attr('disabled', true);
      $stationDrp.find('div[data-toggle="dropdown"]').addClass('prevent-event');
      $stationDrp.find('.js-require-marker').hide();

      $inputText.attr('required', !!valueChanged);
    });
  }

  function handleDropDownStation () {
    $stationDrp = $('.js-country-station').drpAutoComplete();

    $stationDrp.on('selectedItem.drpAutoComplete', function (e, item, showVisibleItems) {
      handleItemsOfStationBasedOnCountry(currentCountrySelected, showVisibleItems);
    });

    $stationDrp.on('inputChange.drpAutoComplete', function (e, value) {
      if (!value) {
        handleItemsOfStationBasedOnCountry(currentCountrySelected);
      }
    });
  }

  function handleDropDownPhone () {
    var $phoneCodes = $('.js-drp-phone-code');

    $phoneCodes.each(function () {
      $(this).drpAutoComplete();
    });
  }

  function getCountry () {
    var api = window.standardUrl('/api/country.json');

    return window.APICaller.call(api);
  }

  function populateData () {
    getCountry().then(function (res) {
      populateCountry(res.data);
      populateStation(res.data);
      populatePhoneCode(res.data);
    });
  }

  function populateCountry (data) {
    autoRenderHTML('#countryTemplate', data);
    handleDropDownCountry(data);
  }

  function populateStation (data) {
    autoRenderHTML('#stationTemplate', data);
    handleDropDownStation();
  }

  function populatePhoneCode (data) {
    autoRenderHTML('#phoneTemplate', data);
    handleDropDownPhone(data);
  }

  function autoRenderHTML (selector, data) {
    var $template = $(selector);
    if (!$template || !$template.length || !data) {
      return;
    }

    var htmlTpl = $template.html();
    var template = Handlebars.compile(htmlTpl);
    var htmlCompiled = template({
      data: data
    });

    $template.parent().append(htmlCompiled);
  }

  populateData();
});
